import React, { useState } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Profile from "./profile";
import InviteModal from "./inviteModal";
import HeaderComponent from "./components/header/header";
import DividerComponent from "./components/divider";
import GuestBook from "./components/guestBook/guestBook";
import PostList from "./components/post/postList";
import PostDetail from "./components/post/postDetail";

function App() {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  return (
    <BrowserRouter>
      <div>
        <HeaderComponent />
      </div>

      <DividerComponent />

      <Routes>
        {/* 메인 페이지에 Profile과 PostList를 렌더링 */}
        <Route path="/" element={<>
          <Profile />
          <PostList />
          <DividerComponent />
          <GuestBook />
        </>} />

        {/* 특정 경로에 PostList만 렌더링 */}
        <Route path="/posts" element={<PostList />} />

        {/* 특정 프로필 페이지 */}
        <Route path="/profile" element={<Profile />} />

        {/* 방명록 페이지 */}
        <Route path="/guestbook" element={<GuestBook />} />

        {/* 동적 라우팅: Post ID에 따른 포스트 페이지 */}
        <Route path="/posts/:id" element={<PostDetail />} />
      </Routes>

      {/* 모달 로직 */}
      {isModalOpen && <InviteModal onClose={handleCloseModal} />}
    </BrowserRouter>
  );
}

export default App;
