import "./guestBook.css";
import React, { useState, useEffect } from "react";
import axios from "axios";

function GuestBook() {
  const [entries, setEntries] = useState([]);
  const [error, setError] = useState(null);
  const [name, setName] = useState("");
  const [message, setMessage] = useState("");
  const [password, setPassword] = useState("");

  useEffect(() => {
    fetchEntries();
  }, []);

  const fetchEntries = async () => {
    try {
      const response = await axios.get("/api/entries");
      setEntries(response.data);
      setError(null);
    } catch (err) {
      setError("방명록을 불러오는 데 실패했습니다.");
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    await axios.post("/api/entries", { name, message, password });
    fetchEntries();
    setName("");
    setMessage("");
    setPassword("");
  };
  return (
    <div>
      <h3>방명록</h3>
      <form onSubmit={handleSubmit}>
        <input
          type="text"
          value={name}
          onChange={(e) => setName(e.target.value)}
          placeholder="이름 (비워두면 익명으로 표시됩니다.)"
        />
        <br></br>
        <textarea
          type="text"
          value={message}
          onChange={(e) => setMessage(e.target.value)}
          placeholder="주인장에게 남길 메시지"
          required
        />
        <br></br>
        <input
          type="password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          placeholder="비밀번호"
          required
        />
        <button className="button">방명록 작성하기</button>
      </form>
      <div>{error && <p style={{ color: "red" }}>{error}</p>} </div>
      {/* 에러 메시지 표시 */}
      <ul>
        {entries.map((entry) => (
          <li key={entry.id}>
            <strong>{entry.name}</strong>: {entry.message}
          </li>
        ))}
      </ul>
    </div>
  );
}

export default GuestBook;
