function DividerComponent() {
  const dividerStyle = {
    border: "0",
    height: "1px",
    backgroundImage:
      "linear-gradient(to right, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.75), rgba(0, 0, 0, 0))",
  };
  return <hr style={dividerStyle} />;
}

export default DividerComponent;
