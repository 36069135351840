import React from "react";

function Profile() {
  return (
    <div>
      <p>안녕하세요 저는 Hamish 입니다.</p>
      <p>다양한 경험과 함께 스스로 변화하는 것을 좋아하고, 지금은 하고 있는 일에 좀 더 집중하고 있어요.</p>
      <p>블로그에는 개발 이야기와 생각을 적으려고 합니다.</p>
    </div>
  );
}

export default Profile;
