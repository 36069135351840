import React, { useEffect, useState } from 'react';

const PostDetail = ({ postId }) => {
    const [post, setPost] = useState(null);

    useEffect(() => {
        // Fetch the post details based on the postId
        fetch(`https://api.example.com/posts/${postId}`)
            .then(response => response.json())
            .then(data => setPost(data))
            .catch(error => console.log(error));
    }, [postId]);

    if (!post) {
        return <div>Loading...</div>;
    }

    return (
        <div>
            <h1>{post.title}</h1>
            <p>{post.content}</p>
        </div>
    );
};

export default PostDetail;