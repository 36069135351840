import React from "react";

function HeaderComponent() {
  const headerStyle = {
    backgroundImage: `url(${process.env.PUBLIC_URL}/header.jpeg)`,
    backgroundSize: "cover",
    height: "300px", // 원하는 높이로 조절 가능
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    color: "white", // 제목의 텍스트 색상
  };

  return (
    <div style={headerStyle}>
      <h1>Hamish Blog</h1>
    </div>
  );
}

export default HeaderComponent;
