import React, { useEffect, useState } from 'react';

const PostList = () => {
    const [posts, setPosts] = useState([]);

    // useEffect(() => {
    //     fetch('https://api.example.com/posts')
    //         .then(response => response.json())
    //         .then(data => setPosts(data))
    //         .catch(error => console.log(error));
    useEffect(() => {
        const mockData = [
            { id: 1, title: 'Mock Post 1', content: 'This is the content of Mock Post 1' },
            { id: 2, title: 'Mock Post 2', content: 'This is the content of Mock Post 2' },
            { id: 3, title: 'Mock Post 3', content: 'This is the content of Mock Post 3' },
        ];
        setPosts(mockData);
    }, []);

    return (
        <div>
            <h1>Posts</h1>
            <ul className="list-group">
                {posts.map(post => (
                    <li key={post.id} className="list-group-item">
                        <a href={`/posts/${post.id}`} className="text-decoration-none">{post.title}</a>
                    </li>
                ))}
            </ul>
        </div>
    );

};

export default PostList;