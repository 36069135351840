function InviteModal({ onClose }) {
  return (
    <div
      style={{
        position: "fixed",
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        backgroundColor: "rgba(0, 0, 0, 0.5)",
      }}
    >
      <div
        style={{
          margin: "100px auto",
          backgroundColor: "white",
          padding: "20px",
          width: "50%",
        }}
      >
        <h1>문상헌 집에서 자기</h1>
        <button onClick={onClose}>닫기</button>
      </div>
    </div>
  );
}

export default InviteModal;

